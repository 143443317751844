// src/components/Gallery.js
import React, { useEffect, useRef, useState } from 'react'
import './Gallery.css' // Importing the CSS for styling
import SelectedImage from '../SelectedImage/SelectedImage'

const paintingsDir = 'images/paintings/'

const Gallery = ({ items = [], galleryName = '' }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [showDetails, setShowDetails] = useState(true)
  const [showGallery, setShowGallery] = useState(true)
  const [currentImage, setCurrentImage] = useState(0)

  const handleImageClick = image => {
    setSelectedImage(image)
  }
  const closeDetails = () => {
    setShowDetails(false)
  }

  return (
    <div className='parent'>
      {showGallery && (
        <div className='gallery'>
          {items.map((image, index) => {
            // Extract the file extension from the first URL in the image.url array
            const fileExtension = image.url[0].split('.').pop()

            // Determine the correct HTML element to use based on the file type
            const mediaElement =
              fileExtension === 'mp4' ? (
                <video
                  className='gallery-item'
                  src={`${paintingsDir}${image.url[0]}`}
                  alt={`Gallery item ${index}`}
                  autoPlay={true}
                  muted
                  onClick={() => handleImageClick(image)}
                />
              ) : (
                <img
                  src={`${paintingsDir}${image.url[0]}.jpg`}
                  alt={`Gallery item ${index}`}
                  onClick={() => handleImageClick(image)}
                />
              )

            return (
              <div key={index} className='gallery-item'>
                {mediaElement}
              </div>
            )
          })}
          {selectedImage && (
            <div className='modal'>
              {selectedImage.url.length > 1 && (
                <p
                  className='arrow left'
                  onClick={() => {
                    if (currentImage !== 0) {
                      setCurrentImage(prev => prev - 1)
                    } else {
                      setCurrentImage(selectedImage.url.length - 1)
                    }
                  }}
                >
                  <span className='highlight'>{'<'}</span>
                </p>
              )}
              <SelectedImage
                image={selectedImage.url[currentImage]}
                callback={() => {
                  setSelectedImage(null)
                  setCurrentImage(0)
                  setShowDetails(true)
                }}
              />
              {selectedImage.url.length > 1 && (
                <p
                  className='arrow right'
                  onClick={() => {
                    if (currentImage !== selectedImage.url.length - 1) {
                      setCurrentImage(prev => prev + 1)
                    } else {
                      setCurrentImage(0)
                    }
                  }}
                >
                  <span className='highlight'>{'<'}</span>
                </p>
              )}

              {showDetails && (
                <div className='details' onClick={closeDetails}>
                  <div className='x-button'>X</div>
                  {/* Render title if it exists */}
                  {selectedImage.title && (
                    <div>
                      כותרת: {selectedImage.title} <br />
                    </div>
                  )}
                  {/* Render description if it exists */}
                  {selectedImage.description && (
                    <div>
                      תיאור: {selectedImage.description} <br />
                    </div>
                  )}
                  {/* Always render medium and size as they seem mandatory */}
                  גודל: {selectedImage.size} <br />
                  מדיום: {selectedImage.medium} <br />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Gallery
