import './App.css'
import { useState } from 'react'
import Gallery from './Components/Gallery/Gallery'

function App () {
  const [cursor, setCursor] = useState('cursor-idle')

  const handleMouseDown = () => setCursor('cursor-clicked')
  const handleMouseUp = () => setCursor('cursor-idle')

  const images = [
    {
      title: 'ארבעה נופים מההר',
      description: '',
      medium: 'פחם על נייר',
      size: 'A4',
      url: [`four-views-from-the-mountain`]
    },
    {
      title: 'עיגולי וחכמון',
      description: 'משחק מחשב שאני עובד עליו כבר הרבה זמן',
      medium: 'שמן, טושים, דיגיטלי',
      size: 'כמעט שנתיים',
      url: ['igooli-and-hakmon.mp4', 'igooli', 'hakmon']
    },
    {
      title: '',
      description: 'עוג ואני 3>',
      medium: 'עיפרון, פחם וגירים צבעוניים על נייר',
      size: ' A5',
      url: ['dogfight']
    },
    ,
    {
      title: '',
      description: '',
      medium: 'Pixel 4A 5G',
      size: '1,200 קמ"ר',
      url: ['golan', 'golan-1']
    },
    {
      title: 'איל רועה נגד עיריית תל אביב-יפו',
      description:
        'חברי הטוב איל עומד למשפט ערעור על דוח מטופש שחטף כי מכר מיץ היביסקוס. אני הגעתי כרשם בית-משפט. ברישומים ניתן לראות את נציגת העירייה, השופטת, הקלדנית, בחור נוסף שעמד למשפט ואת איל כשהוא מקשיב, מקריא את כתב הערעור, ומראה לשופטת את העובר ושב שלו כדי להוכיח לה שאין לו כסף לשלם את הדוח. ',
      medium: 'עיפרון על נייר',
      size: 'A4',
      url: ['eyal-3', 'eyal-1', 'eyal-2']
    },
    {
      title: '',
      description: 'בעקבות אישה לבושה בכחול של פיקסו',
      medium: 'שמן על נייר עיתון',
      size: 'A3',
      url: ['picasso']
    },
    {
      title: '',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A5',
      url: ['excersise']
    },
    {
      title: '',
      description: '',
      medium: 'עט על נייר',
      size: 'A5',
      url: ['idf-1', 'idf-2', 'idf-3']
    },
    {
      title: '',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A4',
      url: ['judo-2', 'judo-1']
    },
    {
      title: 'רדי-מייד מטופל',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A4',
      url: ['ready-made']
    },
    {
      title: '',
      description: '',
      medium: 'פצע על יד, דיגיטלי',
      size: '2856 על 923 פיקסלים',
      url: ['the-eleventh']
    },
    {
      title: '',
      description: 'כמה רישומים שעשיתי כשהסתובבתי בברלין בקיץ הקודם',
      medium: 'עט, פסטל על נייר',
      size: 'A4',
      url: ['berlin-1', 'berlin-2', 'berlin-3']
    },
    {
      title: 'איש עצוב',
      description: 'איזה פרצוף שנעשה ממש עצוב כשמזיזים אותו עם העכבר',
      medium: 'C++',
      size: '668 MB',
      url: ['sad-man.mp4']
    },
    {
      title: '',
      description: '',
      medium: '3D',
      size: '1.76Gb',
      url: ['bronze-of-artemision', 'sleeping-og', 'good-venus.mp4']
    },
    {
      title: '',
      description: '',
      medium: 'שמן על נייר',
      size: 'A4',
      url: ['delphine']
    },
    {
      title: '',
      description: '',
      medium: 'אקריליק, טושים על נייר',
      size: 'A4',
      url: ['la-dog-dargent-2', 'la-dog-dargent-1']
    },

    {
      title: 'כריש מת זה לא אמנות ואף על פי כן נוע תנוע',
      description:
        "עבודת הגמר שלי בתלמה ילין. הייתה אמורה להיות תלויה עם הפנים כלפי חוץ, אבל ראש המגמה לא הסכימה שיהיה קנבס ענק שכתוב עליו 'משעמם לי' במרכז בית הספר",
      medium: 'פחם על קנבס',
      size: '7.90 מטר על 4.5 מטר',
      url: ['gmar-1', 'gmar-2', 'gmar-3']
    },
    {
      title: '',
      description: '',
      medium: 'פחם על נייר',
      size: 'A5',
      url: ['legs']
    },
    {
      title: '',
      description: '',
      medium: 'שמן על נייר',
      size: 'A4',
      url: ['man-with-hat']
    },

    {
      title: '',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A4',
      url: ['right-hand-moses']
    },
    {
      title: '',
      description: '',
      medium: 'שמן על נייר',
      size: 'A4',
      url: ['soldier-clown']
    },
    {
      title: '',
      description: '',
      medium: 'סרט על צילום',
      size: '1920/1080',
      url: ['amir-eyal.mp4']
    },
    {
      title: '',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A5',
      url: ['sp-as-na']
    },
    {
      title: '',
      description: '',
      medium: 'עיפרון על נייר',
      size: 'A4',
      url: ['the-weightlifter']
    }
  ]
  return (
    <div
      className={cursor}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{ width: '100%', height: '100vh' }} // Set the div size as needed
    >
      <div className='background'>
        <div className='app-parent'>
          <img
            src='/images/mistake-returns.png'
            style={{ maxWidth: '90vw' }}
          ></img>
          <Gallery items={images} galleryName={'עבודות סרוקות'}></Gallery>
        </div>
        <div className='allrights'>
          All rights reserverd Avinoanm Cooper © 2024
        </div>
      </div>
    </div>
  )
}

export default App
