import React from 'react'
import './SelectedImage.css'

const SelectedImage = ({ image, callback }) => {
  const fileExtension = image.split('.').pop()
  const paintingsDir = 'images/paintings/'

  return fileExtension === 'mp4' ? (
    <video
      onClick={callback}
      className='selected-image'
      src={`${paintingsDir}${image}`}
      autoPlay={true}
      loop
    />
  ) : (
    <img
      onClick={callback}
      className='selected-image'
      src={`${paintingsDir}${image}.jpg`}
      alt={`Selected image`}
    />
  )
}
export default SelectedImage
